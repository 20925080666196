import axios from 'axios'

export const MASSMAILLING = `${process.env.VUE_APP_LINK}/api/v1/send_maillings`;





export default { 
    //Получение данных при загрузке
    getMaillings(data) {
        return axios.get(`${MASSMAILLING}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:data
        })
    },
    getMailling(id) {
        return axios.get(`${MASSMAILLING}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getMaillingInform(id) {
        return axios.get(`${MASSMAILLING}/inform/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getElementStatuses(data) {
        return axios.post(`${MASSMAILLING}/inform/element_statuses`,data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getMaillingStatuses() {
        return axios.get(`${MASSMAILLING}/inform/statuses`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getMaillingJobs() {
        return axios.get(`${MASSMAILLING}/inform/jobs`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    prefSaveMailing(data) {
        return axios.post(`${MASSMAILLING}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    action(id, data) {
        return axios.post(`${MASSMAILLING}/action/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
 
    deleteMailling(id) {
        return axios.delete(`${MASSMAILLING}/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    getAvailableShops(data) {
        return axios.get(`${MASSMAILLING}/available_shops`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:data
        })
    },
    createMailing(data) {
        return axios.post(`${MASSMAILLING}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    updateMailing(id, data) {
        return axios.put(`${MASSMAILLING}/${id}`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    testMailing(data) {
        return axios.post(`${MASSMAILLING}/test`,  data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    maillingToGoogleTableStopAll(data={}) {
        return axios.post(`${MASSMAILLING}/static/google_table/stop/all`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
    maillingToGoogleTable(data) {
        return axios.post(`${MASSMAILLING}/static/google_table/status`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
    },
}